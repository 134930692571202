.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.active {
  background-color: rgb(239 68 68);
  color: white;
}
.pending {
  background-color: white;
  color: black;
}

.posterTransition {
  transition: all ease-in-out 500ms;
}
.posterTransition:hover {
  transform: scale(8.5);
}

@media screen and (max-width: 1400px) {
  .posterTransition:hover {
    transform: translateY(-0.5rem) scale(8.5);
  }
}

.transition-li {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 700ms ease-in-out;
}

.transition-li:hover {
  transform: scale(1.05);
}

.transition-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 700ms ease-in-out;
}

.passbook {
  visibility: hidden;
  transform: translateY(-100%);
  pointer-events: none;
  cursor: auto;
  animation: 700ms fadeOut;
  animation-fill-mode: forwards;
}

.transition-passbook {
  pointer-events: auto;
  animation: 700ms fadeIn;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    transform: translateY(-80%);
    opacity: 0;
  }
  100% {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    visibility: hidden;
    transform: translateY(-80%);
    opacity: 0;
  }
}

.username {
  position: absolute;
  width: 15rem;
  top: 3.8rem;
  right: 0;
  overflow: hidden;
  transform: translateX(107%);
  transition: all 700ms ease-in-out;
}

.username-transition {
  transform: translateX(-2%);
}

.w-custom {
  display: inline-block;
  width: 100%;
  overflow-x: scroll;
  background-color: red;
}

.w-table {
  display: inline-block;
  width: 100%;
  height: fit-content;
  overflow-x: scroll;
  background: yellow;
}

.sidebar {
  position: absolute;
  top: 2vh;
  left: 0;
  width: 12rem;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: all 700ms ease-out;
}

.sidebar-admin {
  position: absolute;
  left: 0;
  width: 12rem;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: all 700ms ease-out;
}

.sidebar-transition {
  transform: translateX(0);
}

.home {
  width: 100vw;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 16rem 1fr;
  column-gap: 1.2rem;
  padding-right: 1.6rem;
}

.toast-message {
  width: max-content;
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .toast-message {
    margin-top: 2rem;
  }
}

.dateTimePicker {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: inherit;
}

.dateTimePicker > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.2rem;
}

.react-datetime-picker__wrapper {
  border: 2px solid rgb(239 68 68) !important;
  border-radius: 6px;
  padding: 0.1rem 0.2rem;
}

@media screen and (max-width: 1073px) {
  .dateTimePicker {
    flex-direction: column;
    gap: 0.4rem;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .dateTimePicker > div {
    width: 100%;
  }

  .dateTimePicker > div > * {
    width: inherit;
  }
}

.bet-card {
  background-color: yellow;
  margin: 0 2rem;
}

@media (min-width: 640px) {
  .bet-card {
    margin: 0;
  }
}

.setting-animate {
  animation: 300ms settingRotate;
  animation-fill-mode: forwards;
}

@keyframes settingRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

.user-table {
  margin-bottom: 1rem;
}

.user-table > div {
  width: 100%;
}

.react-datetime-picker__clock {
  display: none;
}

.login-loading {
  /* font-weight: bold; */
    display: inline-block;
    /* font-family: monospace; */
    font-size: inherit;
    clip-path: inset(0 1.3ch 0 0);
    animation: l 1.4s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -0.6ch 0 0)
  }
}
